/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Modal from "./modal"
import Form from "./form"

const CustomFormModal = ({
  onClose,
  onSubmit,
  dataForm,
  detail,
  goalName,
  addFields,
  downloadPlan,
  title,
  text
}) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSection(slug: { eq: "gateway-form-for-general-inquiry" }) {
        content {
          internal {
            content
          }
        }
      }
    }
  `)

  let formData = dataForm
    ? JSON.parse(dataForm.content.internal.content)
    : JSON.parse(data.contentfulSection.content.internal.content)

  if (formData.detailField && detail)
    formData.defaultFields = {
      ...formData.defaultFields,
      [formData.detailField]: detail,
    }

  if (formData.gaEventLabel && goalName) {
    formData.gaEventLabel = goalName
  }

  if (addFields) {
    let obj = { ...addFields }

    delete obj.defaultFields

    // add and update defaultFields i.e. innerObject

    if (addFields.defaultFields) {
      // for(let key in formData.defaultFields){
      //     if(addFields.defaultFields[key]){
      //         formData.defaultFields[key] = addFields.defaultFields[key];
      //     }
      //     else if(!formData.defaultFields[key]){
      //         console.log("enter",key ,"key",formData.defaultFields[key],"da",addFields.defaultFields[key])
      //         formData.defaultFields[key] = addFields.defaultFields[key];
      //     }
      // }
      const defaultFields = {
        ...formData.defaultFields,
        ...addFields.defaultFields,
      }
      formData.defaultFields = defaultFields
    }

    // update keys and Value of formData

    formData = { ...formData, ...obj }
  }

  // console.log("Form==>",formData);

  return (
    <Modal
    title={title}
    text={text}
      customStyle={{ maxWidth: [null, null, null, 700] }}
      onClose={onClose}
    >
      <Form
        formData={formData}
        onSubmit={onSubmit}
        noSteps={true}
        type="secondary"
        modalClose={onClose}
        downloadPlan={downloadPlan}
      ></Form>
    </Modal>
  )
}

export default CustomFormModal

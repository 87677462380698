/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import React from "react"
import get from "lodash/get"

import { findBySlug } from "../helpers"

import AdvantagesModule from "../components/advantages-module"
import BenefitsModule from "../components/benefits-module"
import CTAsModule from "../components/ctas-module"
import Hero from "../components/hero"
import Page from "../components/page"
import ProjectGalleryModule from "../components/project-gallery-module"
import ProjectUnique from "../components/project-unique"
import ProcessModule from "../components/process-module"
import TestimonialsModule from "../components/testimonials-module"
import CustomFormModal from "../components/custom-form-modal"
import Quote from "../components/design-partner/quote"

import { paramsCheck } from "../functions/functions"

const ArchitectsAndDesigners = ({ data }) => {
  paramsCheck();
  const [isReady, setIsReady] = React.useState(false)
    const checkIfWindow = () => {
      if (typeof window !== "undefined") {
        return typeof window !== "undefined"
      }
      return typeof window !== "undefined"
    }
  
    React.useEffect(() => {
      if (checkIfWindow) {
        window.location.href = "/"
        setIsReady(true)
      }
    }, [])
  const renderHeroModule = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "architects-hero-module",
    })
    const heroHeights = ["auto", 555]
    if (
      !heroBladePatternData ||
      !get(heroBladePatternData, "desktopImage.file.url")
    ) {
      return
    }
    const ctaList = [
      {
        text: heroBladePatternData.ctaTitle,
        type: "secondary",
        link: heroBladePatternData.ctaDestination || "/",
      },
    ]

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        title={heroBladePatternData.title}
        ctaList={ctaList}
        body={get(heroBladePatternData, "description.description")}
        type="secondary"
      />
    )
  }

  const [showModal, setShowModal] = React.useState(false)
  const [gatewayLink, setGatewayLink] = React.useState({
    to: "/",
    type: "internal",
  })
  const [customField, setCustomField] = React.useState()

  const addField = {
    defaultFields: {
      tags: "PDGDownload",
      "Lead Source": "Web",
      "Lead Source Details": "",
    },
    detailField: "Lead Source Details",
  }

  // const apiCall = {
  //   "callOnlyloggerUrl" : true
  // }

  const renderBenefitsModule = () => {
    const benefitsModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "architects-benefits-module",
    })

    if (!benefitsModuleBladePatternData) return

    return (
      <div>
        {showModal && (
          <CustomFormModal
            title={"Download"}
            text={"Please answer a few quick questions to help us understand your needs."}
            onClose={() => setShowModal(false)}
            // dataForm = {architectForm}
            addFields={customField}
            onSubmit={() => {
              let win = window.open(gatewayLink.to, "_blank")
              win.focus()
            }}
            downloadPlan={() => {
              let win = window.open(gatewayLink.to, "_blank")
              win.focus()
            }}
          />
        )}
        <BenefitsModule
          bladePatternData={benefitsModuleBladePatternData}
          ctaOnClick={(e, index, href) => {
            // if(index === 3){
            //   const obj = { ...apiCall , ...addField  }
            //   setCustomField(obj);
            // }else{
            //   setCustomField('');
            // }
            if (index !== 3) {
              e.preventDefault()

              if (index === 0) {
                addField.defaultFields["Lead Source Details"] =
                  "PBS Overview Download"
                addField.defaultFields.tags = "PBSDownload"
                addField.detailField = "PBS Overview Download"
                addField.gaEventLabel = "PBS Overview Download Form Submit"
                addField.conversionDetail = "xXoxCM_Wz9wBEJeLhv0C"
                setCustomField(addField)
              } else if (index === 2) {
                addField.defaultFields["Lead Source Details"] =
                  "Download Plant Design Guidelines"
                addField.defaultFields.tags = "PDGDownload"
                addField.detailField = "Download Plant Design Guidelines"
                addField.gaEventLabel = "Design Guidelines Download Form Submit"
                addField.conversionDetail = "PygNCPGkq9UBEJeLhv0C"

                setCustomField(addField)
              } else {
                setCustomField("")
              }

              setShowModal(true)
              if (href) setGatewayLink(href)
            }
          }}
        />
      </div>
    )
  }
  const renderServicesModule = () => {
    const servicesModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "architects-services-module",
    })

    if (!servicesModuleBladePatternData) return

    return <ProjectUnique data={servicesModuleBladePatternData} />
  }
  const renderProcessModule = () => {
    const processTextModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "architects-process-text-module",
    })

    if (!processTextModuleBladePatternData) return

    return (
      <ProcessModule bladePatternData={processTextModuleBladePatternData} />
    )
  }
  const renderTestimonialsModule = () => {
    const testimonialsBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "architects-testimonials-module",
    })
    if (!testimonialsBladePatternData) return

    return (
      <TestimonialsModule bladePatternData={testimonialsBladePatternData} />
    )
  }
  const renderAdvantagesModule = () => {
    const advantagesBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "architects-advantages-module",
    })
    if (!advantagesBladePatternData) return

    return <AdvantagesModule bladePatternData={advantagesBladePatternData} />
  }
  const renderCtaModule = () => {
    const ctaModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "architects-cta-module",
    })

    if (!ctaModuleBladePatternData) return

    return (
      <CTAsModule
        textStyle={{ color: theme => theme.colors.white }}
        bladePatternData={ctaModuleBladePatternData}
      />
    )
  }
  const renderProjectGalleryModule = () => {
    const projectGalleryModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "architects-gallery-module",
    })

    if (!projectGalleryModuleBladePatternData) return

    return (
      <ProjectGalleryModule
        bladePatternData={projectGalleryModuleBladePatternData}
        projectsData={data.allContentfulProject}
      />
    )
  }

  const renderQuote = () => {
    if (
      !data.allContentfulBladePattern ||
      !data.allContentfulBladePattern.edges.length > 0 ||
      data.allContentfulBladePattern.edges[0].node.quote === null
    )
      return
    else {
      const bladePatternData = data.allContentfulBladePattern.edges[0].node

      return (
        <div
          sx={{
            backgroundColor: theme => theme.colors.backgrounds.primary,
            py: theme => theme.spacing.vertical.lg,
          }}
        >
          {bladePatternData.quote.map(quote => (
            <Quote name={quote.name} quote={quote} photo={quote.image} />
          ))}
        </div>
      )
    }
  }

  return (
    isReady ?<Page data={data.contentfulPage}>
      {renderHeroModule()}
      {renderBenefitsModule()}
      {renderServicesModule()}
      {renderProcessModule()}
      {renderProjectGalleryModule()}
      {renderTestimonialsModule()}
      {renderAdvantagesModule()}
      {renderCtaModule()}
      {renderQuote()}
    </Page>: <></>
  )
}

export default ArchitectsAndDesigners

export const query = graphql`
  query architectsPageQuery {
    contentfulPage(slug: { eq: "architects-and-designers" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulBladePattern(
      filter: { slug: { eq: "architects-and-designers-quote-module" } }
    ) {
      edges {
        node {
          id
          name
          slug
          quote {
            name
            quote {
              json
            }
            image {
              file {
                url
              }
            }
          }
        }
      }
    }

    allContentfulProject(limit: 3, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          projectImages {
            id
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          slug
        }
      }
    }
  }
`
